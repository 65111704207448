export const colorsPalette = [
  {
    name: 'Mocca brąz',
    ral: 'RAL 8017',
    hex: '#45322E',
  },
  {
    name: 'Księżycowy antracyt',
    ral: 'RAL 7016',
    hex: '#293133',
  },
  {
    name: 'Wulkaniczny grafit',
    ral: 'RAL 7024',
    hex: '474A51',
  },
  {
    name: 'Karbonowa czerń',
    ral: 'RAL 9005',
    hex: '#0A0A0A',
  },
  {
    name: 'Słoneczny żółty',
    ral: 'RAL 1018',
    hex: '#F8F32B',
  },
  {
    name: 'Szafranowy pomarańcz',
    ral: 'RAL 2000',
    hex: '#ED760E',
  },
  {
    name: 'Winny czerwony',
    ral: 'RAL 3005',
    hex: '#5E2129',
  },
  {
    name: 'Klasyczny szafir',
    ral: 'RAL 5003',
    hex: '#1D1E33',
  },
  {
    name: 'Intensywny kobalt',
    ral: 'RAL 5010',
    hex: '#0E294B',
  },
  {
    name: 'Energetyczna limonka',
    ral: 'RAL 6018',
    hex: '#57A639',
  },
  {
    name: 'Mroźne marengo',
    ral: 'RAL 7021',
    hex: '#23282B',
  },
  {
    name: 'Stalowy szary',
    ral: 'RAL 7030',
    hex: '#8B8C7A',
  },
  {
    name: 'Soczysta zieleń',
    ral: 'RAL 6005',
    hex: '#2F4538',
  },
  {
    name: 'Czekoladowy brąz',
    ral: 'RAL 8019',
    hex: '#403A3A',
  },
  {
    name: 'Metalizowane srebro',
    ral: 'RAL 9006',
    hex: '#A5A5A5',
  },
  {
    name: 'Porcelanowa biel',
    ral: 'RAL 9016',
    hex: '#F6F6F6',
  },
]
