import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazyImage'

import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  heading: string
  description1: string
  description2: string
  smallImg: ImageDataLike
  bigImg: ImageDataLike
}

const Section = styled.section`
  padding: 20px 0 60px;
  background-color: ${({ theme }) => theme.colors.white};
`

const StyledContainer = styled(Container)``

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
  }
`

const Hr = styled.div`
  width: 80px;
  height: 8px;
  margin: 0 auto 15px auto;
  background-color: ${({ theme }) => theme.colors.primary300};
  border-radius: 3px;

  ${({ theme }) => theme.media.lg.min} {
    margin: 0 0 15px 0;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;

  ${({ theme }) => theme.media.lg.min} {
    width: 50%;
  }
`

const ContentWrapper = styled.div``

const BigImageWrapper = styled.div`
  padding-top: 40px;
`

const FenceAboutLong: React.FC<Props> = ({
  heading,
  description1,
  description2,
  smallImg,
  bigImg,
}) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      <StyledContainer>
        <InnerWrapper>
          <ImageWrapper>
            <LazyImage src={smallImg} alt="ogrodzenie"></LazyImage>
          </ImageWrapper>
          <ContentWrapper>
            <Heading
              as="h2"
              size={lg ? 20 : 22}
              weight={700}
              themecolor="primary300"
              transform="uppercase"
              align={lg ? 'left' : 'center'}
              margin="15px"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
            <Hr />

            <Text
              size={lg ? 16 : 15}
              themecolor="black"
              weight={500}
              align={lg ? 'left' : 'center'}
              margin="15px"
              dangerouslySetInnerHTML={{ __html: description1 }}
            />

            <Text
              size={lg ? 16 : 15}
              weight={500}
              themecolor="black"
              align={lg ? 'left' : 'center'}
              margin="15px"
              dangerouslySetInnerHTML={{ __html: description2 }}
            />
          </ContentWrapper>
        </InnerWrapper>
        <BigImageWrapper>
          <LazyImage src={bigImg} alt="ogrodzenie"></LazyImage>
        </BigImageWrapper>
      </StyledContainer>
    </Section>
  )
}

export default FenceAboutLong
