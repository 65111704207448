import React from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'
import type { ColorSingle } from 'components/layout/Sections/Colors/Colors'

type Palette = {
  paletteNumber: number
  description: string
  colors: ColorSingle[]
}

type Props = {
  palettes: Palette[]
  info: string
}

const Section = styled.section`
  width: 100%;
  padding: 40px 0 30px;
  background-color: ${({ theme }) => theme.colors.gray100};
`

const PalettesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
  }
`

const PaletteSingleWrapper = styled.div<{ secoundColumnDivided: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${({ theme }) => theme.media.lg.min} {
    width: 40%;
    margin-bottom: 0;

    :first-of-type {
      padding-right: 5.5%;
    }

    &:before {
      content: '';
      display: block;
      width: 50px;
      height: 8px;
      margin-bottom: 20px;
      background-color: ${({ theme }) => theme.colors.primary};
      border-radius: 5px;
    }

    ${({ secoundColumnDivided }) =>
      secoundColumnDivided &&
      css`
        div {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 15px;
        }
      `}
  }
`

const ColorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin-top: 15px;
`

const ColorSingleWrapper = styled.div<{
  color: string
  withBorder?: boolean
  textDark?: boolean
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ color }) => color};
  height: 55px;
  margin-bottom: 15px;
  padding: 8px;

  ${({ withBorder }) =>
    withBorder &&
    css`
      padding: 7px;
      border: 1px solid ${({ theme }) => theme.colors.black};
    `}

  ${({ theme }) => theme.media.lg.min} {
    width: 340px;
  }
`

const FenceColors: React.FC<Props> = ({ palettes, info }) => {
  return (
    <Section>
      <Container>
        <PalettesWrapper>
          {palettes?.map(({ paletteNumber, description, colors }, index) => (
            <PaletteSingleWrapper
              key={index}
              secoundColumnDivided={paletteNumber === 2 && colors?.length > 6}
            >
              <Text
                weight={500}
                themecolor="black"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <ColorsWrapper>
                {colors?.map(({ color, name, label, border, textDark }) => (
                  <ColorSingleWrapper
                    key={color}
                    color={color}
                    withBorder={border}
                  >
                    <Text
                      size={14}
                      themecolor={textDark ? 'black' : 'white'}
                      weight={600}
                      transform="uppercase"
                      dangerouslySetInnerHTML={{ __html: label }}
                    />
                    <Text
                      size={14}
                      themecolor={textDark ? 'black' : 'white'}
                      dangerouslySetInnerHTML={{ __html: name }}
                    />
                  </ColorSingleWrapper>
                ))}
              </ColorsWrapper>
            </PaletteSingleWrapper>
          ))}
        </PalettesWrapper>
        <Text dangerouslySetInnerHTML={{ __html: info }} />
      </Container>
    </Section>
  )
}

export default FenceColors
