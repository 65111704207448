import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import Icon from 'components/shared/icon'
import { Text } from 'components/shared/typography'

import warrantyIcon from 'assets/icons/features/warranty-7-years.png'
import counterweightIcon from 'assets/icons/features/brama_przesuwna_bez_przeciwwagi.svg'
import cornerIcon from 'assets/icons/features/brama_narożna.svg'
import slidingIcon from 'assets/icons/features/brama_przesuwna.svg'
import foldingIcon from 'assets/icons/features/brama_skladana.svg'
import telescopicIcon from 'assets/icons/features/brama_teleskopowa.svg'
import connectorUIcon from 'assets/icons/features/łącznik_typu_U.svg'
import connectorTIcon from 'assets/icons/features/łącznik_typu_T.svg'

type FenceFeatureIcon =
  | 'warranty-7-years'
  | 'foundation'
  | 'sliding'
  | 'counterweight'
  | 'telescopic'
  | 'two-winged'

type Props = {
  features: Array<{
    icon: FenceFeatureIcon
    label?: string
  }>
}

const Section = styled.section`
  padding: 50px 0;
`

const StyledContainer = styled(Container)``

const FeaturesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 30px;
  justify-content: center;

  ${({ theme }) => theme.media.lg.min} {
    grid-template-columns: repeat(auto-fit, 150px);
  }
  ${({ theme }) => theme.media.xxl.min} {
    grid-template-columns: repeat(auto-fit, 200px);
  }
`

const SingleFeatureWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > ${Text} {
    justify-self: flex-start;
  }

  &:first-child {
    align-self: center;
  }
`

const getIcon = (icon: FenceFeatureIcon) => {
  switch (icon) {
    case 'warranty-7-years':
      return warrantyIcon
    case 'foundation':
      return foldingIcon
    case 'sliding':
      return slidingIcon
    case 'counterweight':
      return counterweightIcon
    case 'telescopic':
      return telescopicIcon
    case 'two-winged':
      return cornerIcon
    default:
      return slidingIcon
  }
}

const FenceFeatures: React.FC<Props> = ({ features }) => {
  return (
    <Section>
      <StyledContainer>
        <FeaturesWrapper>
          {features?.map(({ icon, label }) => (
            <SingleFeatureWrapper key={label}>
              <Icon
                src={getIcon(icon)}
                width={label ? 70 : 160}
                height={label ? 80 : 180}
              />
              {label && (
                <Text
                  align="center"
                  weight={600}
                  dangerouslySetInnerHTML={{ __html: label }}
                />
              )}
            </SingleFeatureWrapper>
          ))}
        </FeaturesWrapper>
      </StyledContainer>
    </Section>
  )
}

export default FenceFeatures
