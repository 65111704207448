import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  description1: string
  description2: string
  description3?: string
}

const Section = styled.section`
  padding: 40px 0;
  background-color: ${({ theme }) => theme.colors.white};
`

const InnerWrapper = styled.div`
  position: relative;
  ${({ theme }) => theme.media.lg.min} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
  }
`

const Hr = styled.div`
  width: 80px;
  height: 8px;
  margin: 15px auto 10px auto;
  background-color: ${({ theme }) => theme.colors.primary300};
  border-radius: 3px;

  ${({ theme }) => theme.media.lg.min} {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    margin: 0;
  }
`

const DescriptionWrapper = styled.div`
  position: relative;

  ${({ theme }) => theme.media.lg.min} {
    width: 68%;

    &:last-child {
      width: 28%;
      padding-left: 30px;
    }
    &:nth-last-child(2):not(&:first-child) {
      padding-left: 30px;
    }
  }
`

const FenceAboutShort: React.FC<Props> = ({
  description1,
  description2,
  description3,
}) => {
  const { lg } = useBreakpoint()

  return (
    <Section>
      {description3 ? (
        <Container>
          <InnerWrapper>
            <DescriptionWrapper>
              <Text
                size={lg ? 16 : 15}
                weight={500}
                themecolor="black"
                align={lg ? 'left' : 'center'}
                dangerouslySetInnerHTML={{ __html: description1 }}
              />
            </DescriptionWrapper>

            <DescriptionWrapper>
              <Hr />
              <Text
                size={lg ? 16 : 15}
                weight={500}
                themecolor="black"
                align={lg ? 'left' : 'center'}
                dangerouslySetInnerHTML={{ __html: description2 }}
              />
            </DescriptionWrapper>
            <DescriptionWrapper>
              <Hr />
              <Text
                size={lg ? 16 : 15}
                weight={500}
                themecolor="black"
                align={lg ? 'left' : 'center'}
                dangerouslySetInnerHTML={{ __html: description3 }}
              />
            </DescriptionWrapper>
          </InnerWrapper>
        </Container>
      ) : (
        <Container narrow>
          <InnerWrapper>
            <DescriptionWrapper>
              <Text
                size={lg ? 16 : 15}
                weight={500}
                themecolor="black"
                align={lg ? 'left' : 'center'}
                dangerouslySetInnerHTML={{ __html: description1 }}
              />
            </DescriptionWrapper>

            <DescriptionWrapper>
              <Hr />
              <Text
                size={lg ? 16 : 15}
                weight={500}
                themecolor="black"
                align={lg ? 'left' : 'center'}
                dangerouslySetInnerHTML={{ __html: description2 }}
              />
            </DescriptionWrapper>
          </InnerWrapper>
        </Container>
      )}
    </Section>
  )
}

export default FenceAboutShort
