import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazyImage'

import { ImageDataLike } from 'gatsby-plugin-image'

type Props = {
  image: ImageDataLike
}

const Section = styled.section`
  padding: 100px 0;
`

const FenceAboutShort: React.FC<Props> = ({ image }) => {
  return (
    <Section>
      <Container>
        <LazyImage src={image} alt="fence-visualisation"></LazyImage>
      </Container>
    </Section>
  )
}

export default FenceAboutShort
