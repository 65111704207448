import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Navigation from 'components/layout/Navigation/index'

import InfoWithButton from 'components/layout/InfoWithButton'
import Hero from 'components/layout/Sections/Hero'
import Products from 'components/layout/Sections/Products'
import FenceAboutMain from 'components/layout/Sections/Fences/FenceAboutMain'
import FenceFeatures from 'components/layout/Sections/Fences/FenceFeatures'
import FenceAboutShort from 'components/layout/Sections/Fences/FenceAboutShort'
import FenceAboutLong from 'components/layout/Sections/Fences/FenceAboutLong'
import FenceVisualisation from 'components/layout/Sections/Fences/FenceVisualisation'
import ProductPdf from 'components/layout/ProductPdf'
import FenceColors from 'components/layout/Sections/Fences/FenceColors'

import { getHexFromRAL } from 'utils/getHexFromRAL'
import useLocation from 'hooks/useLocation'

const getFenceCollectionCrumb = (location: string) => {
  if (location.includes('venta'))
    return {
      label: 'Kolekcja Venta',
      link: '/kolekcja-venta/',
    }
  if (location.includes('cubus'))
    return {
      label: 'Kolekcja Cubus',
      link: '/kolekcja-cubus/',
    }
  if (location.includes('metric'))
    return {
      label: 'Kolekcja Metric',
      link: '/kolekcja-metric/',
    }
  if (location.includes('stante'))
    return {
      label: 'Kolekcja Stante',
      link: '/kolekcja-stante/',
    }
  if (location.includes('linea'))
    return {
      label: 'Kolekcja Linea',
      link: '/kolekcja-linea/',
    }
  return {
    label: '',
  }
}

const FenceModelPage: React.FC<PageProps> = ({ pageContext, data }) => {
  const PAGE = data?.wpPost?.ogrodzenieSingle
  const PAGE_SEO = data?.wpPost?.seo
  const HOMEPAGE = data?.wpPage?.Homepage

  const PRODUCTS =
    React.useMemo(
      () =>
        HOMEPAGE?.productsCollection?.map((item) => ({
          img: item?.productsCollectionImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          label: item?.productsCollectionCollectionName,
          link: item?.link,
          alt: item?.productsCollectionImg?.altText,
        })),
      []
    ) ?? []

  const PALETTES = React.useMemo(
    () => [
      {
        paletteNumber: 1,
        description: PAGE?.fenceColorPalette?.fenceColorPaletteDescription1,
        colors: PAGE?.fenceColorPalette?.fenceColorPaletteColors1?.map(
          (color) => ({
            name: color?.fenceColorPaletteColorCode,
            label: color?.fenceColorPaletteColorName,
            color: getHexFromRAL(color?.fenceColorPaletteColorCode!),
            textDark:
              color?.fenceColorPaletteColorCode?.trim().toUpperCase() ===
                'RAL 9016' ||
              color?.fenceColorPaletteColorCode?.trim().toUpperCase() ===
                'RAL 7024',
            border:
              color?.fenceColorPaletteColorCode?.trim().toUpperCase() ===
                'RAL 9016' ||
              color?.fenceColorPaletteColorCode?.trim().toUpperCase() ===
                'RAL 7024',
          })
        ),
      },
      {
        paletteNumber: 2,
        description: PAGE?.fenceColorPalette?.fenceColorPaletteDescription2,
        colors: PAGE?.fenceColorPalette?.fenceColorPaletteColors2?.map(
          (color) => ({
            name: color?.fenceColorPaletteColorCode,
            label: color?.fenceColorPaletteColorName,
            color: getHexFromRAL(color?.fenceColorPaletteColorCode!),
            textDark:
              color?.fenceColorPaletteColorCode?.trim().toUpperCase() ===
                'RAL 9016' ||
              color?.fenceColorPaletteColorCode?.trim().toUpperCase() ===
                'RAL 7024' ||
              color?.fenceColorPaletteColorCode?.trim().toUpperCase() ===
                'RAL 1018',
            border:
              color?.fenceColorPaletteColorCode?.trim().toUpperCase() ===
                'RAL 9016' ||
              color?.fenceColorPaletteColorCode?.trim().toUpperCase() ===
                'RAL 7024',
          })
        ),
      },
    ],
    []
  )

  const FENCE_FEATURES = React.useMemo(
    () =>
      PAGE?.fenceFeatures?.map((feature) => ({
        icon: feature?.fenceFeaturesFeatureIcon,
        label: feature?.fenceFeaturesFeatureName,
      })),
    []
  )

  const smallImg =
    PAGE?.fenceImgSmall?.localFile?.childImageSharp?.gatsbyImageData

  const bigImg = PAGE?.fenceImgBig1?.localFile?.childImageSharp?.gatsbyImageData

  const visualisationImg =
    PAGE?.fenceImgBig2?.localFile?.childImageSharp?.gatsbyImageData

  const location = useLocation()

  const breadcrumbs = {
    mobile: [
      getFenceCollectionCrumb(location),
      {
        label: pageContext?.slug?.toUpperCase(),
      },
    ],
    desktop: [
      {
        label: 'Home',
        link: '/',
      },
      {
        label: 'Ogrodzenia',
        link: '/ogrodzenia/',
      },
      getFenceCollectionCrumb(location),
      {
        label: pageContext?.slug?.toUpperCase(),
      },
    ],
  }

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.fenceHeroImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.fenceHeroImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        headingMobile={PAGE?.fenceHeading}
        headingDesktop={PAGE?.fenceHeading}
        breadcrumbs={breadcrumbs}
      />
      <FenceAboutMain
        description1={PAGE?.fenceDescriptionLeft}
        description2={PAGE?.fenceDescriptionRight}
        backgroundDesktop="gray200"
      />
      <FenceAboutLong
        heading={PAGE?.fenceTextColor}
        description1={PAGE?.fenceText2}
        description2={PAGE?.fenceText3}
        smallImg={smallImg}
        bigImg={bigImg}
      />
      {PAGE?.fencePdfLink && <ProductPdf pdfLink={PAGE?.fencePdfLink} />}
      <FenceColors
        palettes={PALETTES}
        info={PAGE?.fenceColorPaletteAdditionalText}
      />
      {visualisationImg && <FenceVisualisation image={visualisationImg} />}
      <FenceFeatures features={FENCE_FEATURES} />
      <FenceAboutShort
        description1={
          PAGE?.fenceFeaturesDescriptions?.fenceFeaturesDescription1
        }
        description2={
          PAGE?.fenceFeaturesDescriptions?.fenceFeaturesDescription2
        }
        description3={
          PAGE?.fenceFeaturesDescriptions?.fenceFeaturesDescription3
        }
      />
      {PRODUCTS && (
        <Products
          heading={PAGE?.fenceProductsCarousel?.fenceProductsCarouselHeading}
          products={PRODUCTS}
        />
      )}
      <InfoWithButton text={PAGE?.fenceBottomText} />
    </Layout>
  )
}

export default FenceModelPage

export const query = graphql`
  query FenceModelPage($slug: String) {
    wpPage(slug: { regex: "/strona-glowna/" }) {
      Homepage {
        productsCollection {
          productsCollectionCollectionName
          link
          productsCollectionImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
    wpPost(slug: { eq: $slug }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      ogrodzenieSingle {
        fenceHeroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        fenceHeading
        fenceDescriptionLeft
        fenceDescriptionRight
        fenceImgSmall {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 300
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        fenceTextColor
        fenceText2
        fenceText3
        fencePdfLink
        fenceImgBig1 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        fenceImgBig2 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        fenceColorPalette {
          fenceColorPaletteDescription1
          fenceColorPaletteColors1 {
            fenceColorPaletteColorName
            fenceColorPaletteColorCode
          }
          fenceColorPaletteDescription2
          fenceColorPaletteColors2 {
            fenceColorPaletteColorName
            fenceColorPaletteColorCode
          }
          fenceColorPaletteAdditionalText
        }
        fenceFeatures {
          fenceFeaturesFeatureName
          fenceFeaturesFeatureIcon
        }
        fenceFeaturesDescriptions {
          fenceFeaturesDescription1
          fenceFeaturesDescription2
          fenceFeaturesDescription3
        }
        fenceBottomText
      }
    }
  }
`
